<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>风险点数据管理（安全评估结论）</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="现有风险点" icon="ios-contacts">
            <query-list
              :columnsFormatData="columnsFormat"
              :askData="askData.current"
              :refreshData="update_count"
              main_width="750px"
            ></query-list>
          </TabPane>
          <!-- <TabPane label="历史客户列表" icon="md-contacts">
            <query-list
              :columnsFormatData="columnsFormatDataHistory"
              :askData="askData.history"
              :refreshData="update_count"
              main_width="750px"
            ></query-list>
          </TabPane> -->
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="新增风险点" icon="ios-create">
            <div class="form-wrapper">
              <Form
                ref="formRisk"
                :model="formItem"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line">
                  <Cascader :data="lineList" v-model="formItem.line"></Cascader>
                </FormItem>
                <FormItem label="风险点概述：" prop="riskContent">
                  <Input v-model="formItem.riskContent" placeholder="" />
                </FormItem>
                <FormItem label="风险点详情说明：" prop="description">
                  <Input
                    v-model="formItem.description"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="时间：" prop="evaluateYear">
                  <DatePicker
                    type="year"
                    placeholder=""
                    style="width: 200px"
                    v-model="formItem.evaluateYear"
                  ></DatePicker>
                </FormItem>
                <FormItem label="类别：" prop="riskClass">
                  <Select
                    v-model="formItem.riskClass"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in riskClassChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="分值：" prop="score">
                  <InputNumber
                    :max="100"
                    :min="1"
                    v-model="formItem.score"
                  ></InputNumber>
                </FormItem>
                <FormItem label="专业子系统：" prop="interface">
                  <Select
                    v-model="formItem.interface"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in interfaceChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="严重等级：" prop="grade">
                  <Select v-model="formItem.grade" style="width: 260px">
                    <Option
                      v-for="item in gradeChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="整改要求：" prop="demand">
                  <Select v-model="formItem.demand" style="width: 260px">
                    <Option
                      v-for="item in demandChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="解决方案：" prop="solution">
                  <Input
                    v-model="formItem.solution"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="归档编号：" prop="archive">
                  <Input v-model="formItem.archive" placeholder="" />
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('formRisk')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('formRisk')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
            </div>
          </TabPane>
          <TabPane label="批量上传风险点" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含风险点数据的excel文件：
                  <a href="/public/templates/RiskTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload target="riskResult"></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  </div>
</template>
<script>
import queryList from "@/components/Common/QueryList.vue";
import fileUpload from "@/components/Common/FileUpload.vue";

let userToken = localStorage.getItem("current_user_token");

export default {
  name: "riskResult",
  components: {
    queryList,
    fileUpload,
  },
  props: {},
  data() {
    return {
      askData: { current: "riskList" },
      update_count: 1,
      selectedIndustry: "",
      selectedSale: "",
      industryList: [],
      saleList: [],
      columnsFormat: [
        {
          title: "客户名称",
          key: "company_name",
          width: 200,
          align: "center",
        },
        {
          title: "标签",
          width: 65,
          key: "sale",
          align: "center",
        },
        {
          title: "地址",
          key: "address",
          align: "center",
        },
        {
          title: "联系人",
          width: 160,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  props: {
                    size: "small",
                  },
                },
                params.row.customer_name + "," + params.row.liaison_account
              ),
            ]);
          },
        },
        /*{
          title: "必选子项目",
          align: "center",
          render: (h, params) => {
            return h(
              "div",
              JSON.parse(params.row.total_choice).choice.map(function(item) {
                return h(
                  "p",
                  {
                    style: { margin: "5px 0" }
                  },
                  item.text
                );
              })
            );
          }
        },
        {
          title: "附加子项目",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h(
              "div",
              JSON.parse(params.row.total_choice).extra_choice.map(function(
                item
              ) {
                return h("p", {}, item.text);
              })
            );
          }
        },*/
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "green" },
                  on: {
                    click: () => {
                      this.currentCustomer = params.row;
                      this.currentCustomer.tag = "current";
                      if (params.row.other_language) {
                        let other_language = JSON.parse(
                          params.row.other_language
                        );
                        this.currentCustomer.company_name_en = other_language.English
                          ? other_language.English.company_name
                          : "";
                        this.currentCustomer.address_en = other_language.English
                          ? other_language.English.address
                          : "";
                      } else {
                        this.currentCustomer.company_name_en = "";
                        this.currentCustomer.address_en = "";
                      }
                      //this.liaison_account = JSON.parse(this.currentCustomer.liaison_account);
                      this.selectedIndustry = this.currentCustomer.industry;
                      this.selectedSale = this.currentCustomer.sale;
                      this.$axios
                        .post(this.$url + "/ask_option_data", {
                          userToken: userToken,
                          company_id: params.row.id,
                          ask_data: "customersPerCompany",
                        })
                        .then((response) => {
                          if (response.data.stat === "ok") {
                            this.liaison_account = response.data.res_record;
                          } else {
                            this.$Message.error("未找到数据！");
                          }
                        })
                        .catch((error) => {
                          this.$Message.error(error + "网络错误！操作失败。");
                        });
                    },
                  },
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row, "current");
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      formItem: {
        line: [],
        riskContent: "",
        description: "",
        evaluateYear: "",
        riskClass: [],
        score: 0,
        interface: [],
        grade: "",
        demand: "",
        solution: "",
        archive: "",
      },
      ruleValidate: {
        line: [
          {
            required: true,
            type: "array",
            min: 2,
            message: "请选择线路！",
            trigger: "blur",
          },
        ],
        riskContent: [
          {
            required: true,
            type: "string",
            message: "请输入风险点概况！",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            type: "string",
            message: "请输入风险点详情！",
            trigger: "blur",
          },
        ],
        evaluateYear: [
          {
            required: true,
            type: "date",
            message: "请选择发生时间！",
            trigger: "blur",
          },
        ],
        riskClass: [
          {
            required: true,
            type: "array",
            message: "请选择类别！",
            trigger: "blur",
          },
        ],
        interface: [
          {
            required: true,
            type: "array",
            message: "请选择专业系统！",
            trigger: "blur",
          },
        ],
        score: [
          { type: "number", min: 1, message: "请输入分数！", trigger: "blur" },
        ],
        grade: [
          {
            required: true,
            type: "string",
            message: "请选择严重等级！",
            trigger: "blur",
          },
        ],
        demand: [
          {
            required: true,
            type: "string",
            message: "请选择整改要求！",
            trigger: "blur",
          },
        ],
        solution: [
          {
            required: true,
            type: "string",
            message: "请输入解决方案！",
            trigger: "blur",
          },
        ],
        archive: [
          { required: true, message: "请输入档案编号！", trigger: "blur" },
          { type: "string", min: 2, message: "格式不符！", trigger: "blur" },
        ],
      },
      riskClassChoices: [
        {
          value: "A",
          label: "A",
        },
        {
          value: "B",
          label: "B",
        },
        {
          value: "C",
          label: "C",
        },
        {
          value: "D",
          label: "D",
        },
        {
          value: "E",
          label: "E",
        },
      ],
      interfaceChoices: [
        {
          label: "供电",
          value: "供电",
        },
        {
          label: "土建",
          value: "土建",
        },
        {
          label: "信号",
          value: "信号",
        },
        {
          label: "车辆",
          value: "车辆",
        },
        {
          label: "通信",
          value: "通信",
        },
        {
          label: "安全",
          value: "安全",
        },
        {
          label: "机电",
          value: "机电",
        },
        {
          label: "运营",
          value: "运营",
        },
        {
          label: "消防",
          value: "消防",
        },
      ],
      gradeChoices: [
        {
          label: "灾难性",
          value: "灾难性",
        },
        {
          label: "非常严重",
          value: "非常严重",
        },
        {
          label: "严重",
          value: "严重",
        },
        {
          label: "可接受",
          value: "可接受",
        },
        {
          label: "需重视",
          value: "需重视",
        },
      ],
      demandChoices: [
        {
          label: "计划整改",
          value: "计划整改",
        },
        {
          label: "急需整改",
          value: "急需整改",
        },
        {
          label: "其他",
          value: "/",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  created() {
    this.get_option_list();
  },
  methods: {
    get_option_list() {
      this.$axios
        .post(this.$url + "/ask_static_options", {
          userToken: userToken,
          ask_data: "industry",
        })
        .then((response) => {
          this.industryList = response.data.res_record;
        })
        .catch((error) => {
          this.$Message.error(error + "网络错误！操作失败。");
        });
      this.$axios
        .post(this.$url + "/ask_static_options", {
          userToken: userToken,
          ask_data: "saleCatagory",
        })
        .then((response) => {
          this.saleList = response.data.res_record;
        })
        .catch((error) => {
          this.$Message.error(error + "网络错误！操作失败。");
        });
    },
    confirmBox(value, ch) {
      this.$Modal.confirm({
        title: "确认删除：",
        content: "<p>" + "公司名称：" + value.company_name + "</p>",
        onOk: () => {
          this.recordFormatDel(value.id, ch);
        },
        onCancel: () => {},
      });
    },
    recordFormatDel(id, ch) {
      this.$axios
        .post(this.$url + "/manage_correct", {
          userToken: userToken,
          updateData: this.askData,
          operation: "del_data",
          id: id,
          ch: ch,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.error(error + "网络错误！操作失败。");
        });
    },
    handleAdd() {
      this.liaison_account.push({
        name: "",
        phone: "",
        email: "",
      });
    },
    handleRemove(index) {
      this.liaison_account.splice(index, 1);
    },
    handleResetTag() {
      this.selectedIndustry = null;
    },
    handleSubmitInfo() {
      this.$axios
        .post(this.$url + "/manage_correct", {
          userToken: userToken,
          updateData: "customerInfo",
          operation: "alterData",
          id: this.currentCustomer.id,
          company_name: this.currentCustomer.company_name,
          company_name_en: this.currentCustomer.company_name_en,
          address: this.currentCustomer.address,
          address_en: this.currentCustomer.address_en,
          telephone: this.currentCustomer.telephone,
          fax: this.currentCustomer.fax,
          tag: this.currentCustomer.tag,
          sap_id: this.currentCustomer.sap_id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("公司信息更改成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.error(error + "网络错误！操作失败。");
        });
    },
    handleResetInfo() {
      this.currentCustomer = {};
      this.liaison_account = [];
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 00px;
  width: 750px;
}

.editor-title,
.preview-title {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.re-l {
  min-height: 750px;
  color: #000;
  border: #aaa solid 1px;
  box-shadow: 1px 2px 1px #aaa;
  text-align: center;
  position: relative;
  margin: 5px;
  padding: 0 10px;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
}
select {
  height: 30px;
  width: 300px;
  padding: 0 5px;
}
</style>